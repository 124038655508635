import { Component, HostListener, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { DataService } from "./data.service";
import { RainbowService } from "./rainbow.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "vitual-receptionist";
  curentUrl: any = "";
  userActivity: any;
  userInactive: Subject<any> = new Subject();

  constructor(
    private rainbow: RainbowService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.curentUrl = this.router.url;
    this.router.events.subscribe((response) => {
      if (response instanceof NavigationEnd) {
        this.curentUrl = this.router.url;
      }
    });
    this.translate.setDefaultLang("de");
    this.setTimeout();
    this.userInactive.subscribe(() => {
      if (
        this.curentUrl != "/" &&
        this.curentUrl.indexOf("/dialer/playground") < 0 &&
        this.curentUrl.indexOf("instant-assistance") < 0
      ) {
        this.router.navigateByUrl("/");
      }
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      60000
    );
  }

  @HostListener("window:mousemove") refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
