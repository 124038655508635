import { ChangeDetectorRef, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { sdk } from "./../sdk";
import * as moment from "moment";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import rainbowSDK from "rainbow-web-sdk";

export interface ChatMessage {
  id: string;
  message: string;
  type: string;
  time: string;
}

@Injectable({
  providedIn: "root",
})
export class RainbowService {
  contacts: any[];
  callReceivedSubject = new Subject<boolean>();
  callDisconnectedSubject = new Subject<boolean>();
  currentCall: any;
  hasRemoteVideo: boolean;
  hasLocalVideo: boolean;
  isPIPDisplayed: boolean;
  isRemoteVideoDisplayed: boolean;
  isMuted: boolean = false;

  selectedContact: any;
  chatMessages: ChatMessage[] = [];
  newMessageSubscriber = new Subject<boolean>();
  isFullScreen = false;

  conferenceSession: any = null;
  bubble: any = null;
  isBubbleChat: boolean = false;

  public callState: any = null;

  constructor(private router: Router, private zone: NgZone) {
    this.initSdk();
  }

  initSdk() {
    document.addEventListener(rainbowSDK.RAINBOW_ONREADY, (data) => {
      this.onReady();
    });
    document.addEventListener(rainbowSDK.RAINBOW_ONLOADED, (data) => {
      this.onLoaded();
    });
    rainbowSDK.start();
    rainbowSDK.load();
  }

  onReady() {
    console.log("[Hello World] :: On SDK Ready !");
    let config = rainbowSDK.config.useNewModels(false);
    this.signin();
    this.checkForDevices();
  }

  onLoaded() {
    rainbowSDK
      .initialize(environment.applicationKey, environment.applicationSecret)
      .then(() => {
        console.log("[Hello World] :: Rainbow SDK is initialized!");
      })
      .catch((err: any) => {
        console.log("[Hello World] :: Something went wrong with the SDK.", err);
      });
  }

  signin() {
    rainbowSDK.connection
      .signinOnRainbowOfficial(environment.username, environment.password)
      .then((account: any) => {
        this.getContactDetails();
        this.setupListners();
        this.initConvorsationChangeListner();
        this.initBubble();
      })
      .catch((err: any) => {});
  }

  checkForDevices() {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        stream.getTracks().forEach(function (track) {
          track.stop();
        });
        navigator.mediaDevices
          .enumerateDevices()
          .then((event) => {
            // this.handleAvailableDevices(event)
          })
          .catch((err) => {});
      })
      .catch(function (error) {
        console.log("Error :: Unable to have access to media devices", error);
      });
  }

  getContactDetails() {
    rainbowSDK.contacts.getNetworkContacts().then((contacts: any) => {
      let contactsList = contacts.filter(function (contact: any) {
        return (
          contact.id !== rainbowSDK.contacts.getConnectedUser().id &&
          !contact.isTerminated
        );
      });

      if (contactsList && contactsList.length) {
        contactsList = contactsList.sort(this.compare);
        this.contacts = contactsList as any[];
      }
    });
  }

  compare(a: any, b: any) {
    if (a.lastname.toLowerCase() < b.lastname.toLowerCase()) {
      return -1;
    }
    if (a.lastname.toLowerCase() > b.lastname.toLowerCase()) {
      return 1;
    }
    return 0;
  }

  getContactById(id: string) {
    let found = false;
    let contact;
    for (let i = 0; i < this.contacts.length && !found; i++) {
      if (this.contacts[i].id == id || this.contacts[i].dbId == id) {
        found = true;
        contact = this.contacts[i];
      }
    }
    return contact;
  }

  makeAudioCall(contact: any) {
    this.callReceivedSubject.next(false);    
    this.callDisconnectedSubject.next(false);
    if (rainbowSDK.webRTC.canMakeAudioVideoCall()) {
      return rainbowSDK.webRTC.callInAudio(contact.dbId);
    }
  }

  makeVideoCall(contact: any) {
    this.callReceivedSubject.next(false);
    this.callDisconnectedSubject.next(false);
    if (rainbowSDK.webRTC.canMakeAudioVideoCall()) {
      return rainbowSDK.webRTC.callInVideo(contact.dbId);
    }
  }

  setupListners() {
    document.addEventListener(
      rainbowSDK.contacts.RAINBOW_ONCONTACTINFORMATIONCHANGED,
      (data) => {
        this.onContactsInformationChanged.bind(data);
      }
    );

    document.addEventListener(
      rainbowSDK.webRTC.RAINBOW_ONWEBRTCCALLSTATECHANGED,
      (data) => {
        this.onWebRTCCallChanged(data.detail);
      }
    );

    document.addEventListener(
      rainbowSDK.webRTC.RAINBOW_ONWEBRTCTRACKCHANGED,
      (data) => {
        this.onWebRTCTrackChanged(data.detail);
      }
    );

    document.addEventListener(
      rainbowSDK.conferences.RAINBOW_ONWEBCONFERENCEUPDATED,
      (data) => {
        this.onWebConferenceUpdated(data.detail);
      }
    );
  }

  onContactsInformationChanged(event: any, data: any) {
    // this.changeDet.detectChanges();
    this.zone.run(() => {});

    // var contact = event.detail;
    // let selectedItem = this.selectedContactControl.value;
    // if (selectedItem) {
    //   if (selectedItem.id === contact.id) {
    //     this.changeRecipientValue();
    //   }
    // }
  }

  onWebRTCCallChanged(call: any) {
    this.callState = call.status.value;
    switch (call.status.value) {
      case "active":
        this.callReceivedSubject.next(true);

        // if (call.remoteMedia === 3 && call.type.value === "Video") {
        //   this.displayRemoteVideo(call);
        // } else {
        //   this.hideRemoteVideo(call);
        // }

        // if (call.localMedia === 3 && call.type.value === "Video") {
        //   this.displayLocalVideo(call);
        // } else {
        //   this.hideLocalVideo();
        // }
        if (
          (call.remoteMedia === 2 || call.remoteMedia === 3) &&
          call.type.value === 'Video'
        ) {
          this.displayRemoteVideo(call);
        }

        if (call.remoteMedia === 1 && call.type.value === 'Video') {
          this.hideRemoteVideo(call);
        }

        if (call.localMedia === 3 && call.type.value === 'Video') {
          this.displayLocalVideo(call);
        } else {
          this.hideLocalVideo();
        }

        break;
      case 'dialing':
          this.displayLocalVideo(call);
          break;
      case 'connecting':
          this.displayLocalVideo(call);
          break;
      case "Unknown":
        // this.hideLocalVideo();
        // this.hideRemoteVideo(call);
        this.endCall();
        break;

      default:
        break;
    }

    this.currentCall = call;
  }

  endCall(){
    this.callDisconnectedSubject.next(true);
    let timer = setTimeout(() => {
      clearTimeout(timer);
      this.router.navigateByUrl("/");

      this.callReceivedSubject.next(false);
      this.callDisconnectedSubject.next(true);
      this.currentCall = null;
      this.callState = null;
    }, 3000);
  }

  onWebRTCTrackChanged(call: any) {
    // Manage remote video
    if (call.remoteMedia == 3 && call.type.value === "Video") {
      this.displayRemoteVideo(call);
    } else {
      this.hideRemoteVideo(call);
    }
    // Manage local video
    if (call.localMedia == 3 && call.type.value === "Video") {
      this.displayLocalVideo(call);
    } else {
      this.hideLocalVideo();
    }
  }

  displayRemoteVideo(call: any, type: "contact" | "bubble" = "contact") {
    if (type == "contact") rainbowSDK.webRTC.showRemoteVideo(call.id);
    else rainbowSDK.conferences.showRemoteVideo(call.id);

    this.hasRemoteVideo = true;
  }

  hideRemoteVideo(call: any, type: "contact" | "bubble" = "contact") {

    if (type == 'contact') {
      rainbowSDK.webRTC.hideRemoteVideo(call.id);
    } else {
      rainbowSDK.conferences.hideRemoteVideo(call.id);
    }

    this.hasRemoteVideo = false;
  }

  displayLocalVideo(call: any, type: "contact" | "bubble" = "contact") {
    if (type == "contact") rainbowSDK.webRTC.showLocalVideo(call.id);
    else rainbowSDK.conferences.showLocalVideo(call.id);

    this.hasLocalVideo = true;
  }

  hideLocalVideo(type: "contact" | "bubble" = "contact") {
    if (type == "contact") {
      rainbowSDK.webRTC.hideLocalVideo();
    }

    this.hasLocalVideo = false;
  }

  hideRemote() {
    rainbowSDK.webRTC.hideRemoteVideo(this.currentCall.id);
    this.isRemoteVideoDisplayed = false;
  }

  showRemote() {
    rainbowSDK.webRTC.showRemoteVideo(this.currentCall.id);
    this.isRemoteVideoDisplayed = true;
  }

  addVideo() {
    if(this.currentCall?.id){
      rainbowSDK.webRTC.addVideoToCall(this.currentCall.id);
    }
    
  }

  removeVideo() {
    if(this.currentCall?.id){
      rainbowSDK.webRTC.removeVideoFromCall(this.currentCall.id);
    }
  }

  release() {
    if(this.currentCall?.id){
      rainbowSDK.webRTC.release(this.currentCall.id);
    } else {
      this.endCall();
    }
  }

  mute(type: "contact" | "bubble" = "contact") {
    debugger;
    if (type == "bubble") {
      if (rainbowSDK.webConference.hasActiveWebConferenceSession()) {
        const localParticipantId = this.conferenceSession?.localParticipant?.contact?.id;
        if(localParticipantId){
          rainbowSDK.webConference.updateParticipant(this.conferenceSession, localParticipantId, 'mute');
        }
        this.isMuted = true;
      }
    } else {
      // var conversationId = this.currentCall!.conversationId;
      // var conversation =
      //   rainbowSDK.conversations.getConversationById(conversationId);

      // if (conversationId) {
      //   rainbowSDK.webRTC.muteAudioCall(conversationId);
      //   this.isMuted = true;
      // }

      if (this.currentCall!.id) {
        rainbowSDK.webRTC.muteAudioCall(this.currentCall!.id);
        this.isMuted = true;
      }
    }
  }

  unmute(type: "contact" | "bubble" = "contact") {
    if (type == "bubble") {
      if (rainbowSDK.webConference.hasActiveWebConferenceSession()) {
        // rainbowSDK.webConference.unmuteConferenceAudio(this.conferenceSession);
        const localParticipantId = this.conferenceSession?.localParticipant?.contact?.id;
        if(localParticipantId){
          rainbowSDK.webConference.updateParticipant(this.conferenceSession, localParticipantId, 'unmute');
        }
        this.isMuted = false;
      }
    } else {
      // var conversationId = this.currentCall.conversationId;
      // var conversation =
      //   rainbowSDK.conversations.getConversationById(conversationId);

      // if (conversationId) {
      //   rainbowSDK.webRTC.unmuteAudioCall(conversationId);
      //   this.isMuted = false;
      // }

      if (this.currentCall!.id) {
        rainbowSDK.webRTC.unmuteAudioCall(this.currentCall!.id);
        this.isMuted = false;
      }
    }
  }

  // Chat application
  startConversation(contact: any) {
    this.chatMessages = [];
    return rainbowSDK.conversations.openConversationForContact(contact);
  }

  sendMessage(conversationId: any, messageContent: string) {
    this.zone.run(() => {
      this.chatMessages.push({
        id: "",
        message: messageContent,
        type: "from",
        time: moment().format("HH:mm"),
      } as ChatMessage);
    });
    rainbowSDK.im.sendMessageToConversation(conversationId, messageContent);
  }

  initConvorsationChangeListner() {
    document.addEventListener(
      rainbowSDK.im.RAINBOW_ONNEWIMMESSAGERECEIVED,
      (data) => {
        if (this.isBubbleChat || this.selectedContact) {
          this.onNewMessageReceived(data.detail.message);
        }
      }
    );
  }

  onNewMessageReceived(data: any) {
    if (this.isBubbleChat ? true : data.from.id == this.selectedContact.id) {
      this.newMessageSubscriber.next(true);
      this.chatMessages.push({
        id: data.id,
        message: data.data,
        type: "to",
        time: moment(data.date).format("HH:mm"),
      } as ChatMessage);
    }
  }

  //  confference
  stopWebConference() {
    this.callDisconnectedSubject.next(true);
    this.conferenceParticipantJoined = false;

    let timer = setTimeout(() => {
      clearTimeout(timer);
      if (rainbowSDK.webConference.hasActiveWebConferenceSession()) {
        rainbowSDK.webConference.stopWebConference(this.bubble);
      }
      this.router.navigateByUrl("/");
      this.callReceivedSubject.next(false);
      this.callDisconnectedSubject.next(true);
    }, 3000);
  }

  conferenceParticipantJoined: boolean = false;

  onWebConferenceUpdated(data: any) {
    this.conferenceSession = data;

    switch (data.status) {
      case "ended":
        this.conferenceParticipantJoined = false;
        this.router.navigateByUrl("/");
        break;
      case "connected":
        // let participants = data.getConnectedParticipants();
        let participants = data.getConnectedParticipantsExceptLeader();

        if (participants.length > 0) {
          this.callReceivedSubject.next(true);
          this.conferenceParticipantJoined = true;

          if (data.hasLocalVideo === true) {
            this.displayLocalVideo(data, "bubble");
          } else {
            this.hideLocalVideo("bubble");
          }

          let active = data.videoGallery.filter(
            (val: any) => val.state === "busy"
          );

          if (active.length == 0) {
            this.hasRemoteVideo = false;
            rainbowSDK.webConference.updateMainVideoSession(data.id, "");
          } else {
            this.displayRemoteVideo(data, "bubble");
          }
        } else if (this.conferenceParticipantJoined) {
          this.conferenceParticipantJoined = false;
          this.stopWebConference();
        }

        if (this.isMuted) {
          this.mute("bubble");
        } else {
          this.unmute("bubble");
        }

        break;
      default:
        break;
    }
  }

  // bubble call
  initBubble() {
    let allBubbles = rainbowSDK.bubbles
      .getAllBubbles();

    let bubbles = allBubbles.filter((bubble: any) => bubble.dbId === environment.bubbleId);
    this.bubble = bubbles.length > 0 ? bubbles[0] : null;
  }

  startBubbleConversation() {
    this.chatMessages = [];
    this.isBubbleChat = true;
    return rainbowSDK.conversations.getConversationByBubbleId(this.bubble.dbId);
  }

  sendBubbleMessage(messageContent: string) {
    this.chatMessages.push({
      id: "",
      message: messageContent,
      type: "from",
      time: moment().format("HH:mm"),
    } as ChatMessage);

    rainbowSDK.im.sendMessageToBubble(this.bubble, messageContent);
  }

  makeBubbleAudioCall() {
    // this.callReceivedSubject.next(false);
    // this.callDisconnectedSubject.next(false);
    // this.hasRemoteVideo = false;

    // if (rainbowSDK.webConference.hasActiveWebConferenceSession()) {
    //   // todo :: display error message
    // } else {
    //   this.createConfference();
    // }

    const contact = this.firstBubbleActiveContact();
    if(contact){
      this.makeAudioCall(contact);
    } else {
      this.router.navigateByUrl("/");
    }
  }

  firstBubbleActiveContact(){
    return this.bubble.avatarContacts.find((con:any) => con.status == 'online');
  }

  makeBubbleVideoCall() {
    // this.callReceivedSubject.next(false);
    // this.callDisconnectedSubject.next(false);
    // this.isMuted = false;
    // this.hasLocalVideo = false;

    // this.createConfference("video");

    const contact = this.firstBubbleActiveContact();
    if(contact){
      this.makeVideoCall(contact);
    } else {
      this.router.navigateByUrl("/");
    }
  }



  addVideoToConference() {
    // rainbowSDK.conferences.add(this.conferenceSession);
    rainbowSDK.webConference.addMediaToWebConferenceSession(this.conferenceSession, 'video');
    this.hasLocalVideo = true;
  }

  removeVideoFromConference() {
        // rainbowSDK.conferences.removeVideoFromConference(this.conferenceSession);
    rainbowSDK.webConference.removeMediaFromConferenceSession(this.conferenceSession, 'video');
    this.hasLocalVideo = false;
  }

  createConfference(type: "audio" | "video" = "audio") {
    rainbowSDK.webConference
      .startWebConference(this.bubble)
      .then((webConferenceSession: any) => {
        this.conferenceSession = webConferenceSession;
        if (type == "video") {
          this.addVideoToConference();
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  createNewBubble() {
    let strName = "Call Support";
    let strDescription = "Instant call support";
    let boolWithHistory = false;
    let disableNotifications = false;
    let urlAvatar = null;
    let autoAcceptInvitation = true;

    rainbowSDK.bubbles
      .createBubble(
        strName,
        strDescription,
        boolWithHistory,
        disableNotifications,
        urlAvatar,
        autoAcceptInvitation
      )
      .then((bubble: any) => {
        console.log("-- bubble created successfully --");
        // this.createConfference(bubble);
      })
      .catch((err: any) => {});
  }
}
