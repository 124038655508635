// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  applicationKey: "e86ce5c09c1111eb91ac975b57ec90e0",
  applicationSecret: "c1IsR7VxfDSrNPb2ehGkKOPKx4jKtnuKm2NDcergUGDwyfnysNv3ILtNYa1oFle0",
  username: "empfang.ozzh@bouygues-es.com",
  password: "Qwertz.21",
  bubbleId: "607415bd54f2bc333a6cb14b",
  rainbowAdminUrl: 'https://openrainbow.com/api/rainbow/'
};

// export const environment = {
//   production: true,
//   applicationKey: "e86ce5c09c1111eb91ac975b57ec90e0",
//   applicationSecret: "c1IsR7VxfDSrNPb2ehGkKOPKx4jKtnuKm2NDcergUGDwyfnysNv3ILtNYa1oFle0",
//   username: "empfang.ozzh@bouygues-es.com",
//   password: "Qwertz.21",
//   bubbleId: "607415bd54f2bc333a6cb14b",
//   rainbowAdminUrl: 'https://openrainbow.com/api/rainbow/'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
